import { Middlewares } from "@/app/shared/services/app-router/middlewares";
import Guard from "vue-router-multiguard";

export default [
  {
    path: '/main',
    name: 'Main',
    component: () => import('./views/main.vue'),
    meta: {
      title: 'Главная страница',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/how-to-get-loan',
    name: 'HowToGetLoan',
    component: () => import('./views/howToGetLoan.vue'),
    meta: {
      title: 'Как получить заём',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/how-to-return',
    name: 'HowToReturn',
    component: () => import('./views/howToReturn.vue'),
    meta: {
      title: 'Как вернуть заём',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/about-company',
    name: 'AboutCompany',
    component: () => import('./views/aboutCompany.vue'),
    meta: {
      title: 'О компании',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/documents-page',
    name: 'DocumentsPage',
    component: () => import('./views/documents.vue'),
    meta: {
      title: 'Документы',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('./views/contacts.vue'),
    meta: {
      title: 'Контакты',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: () => import('./views/support.vue'),
    meta: {
      title: 'Центр поддержки',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import('./views/reviews.vue'),
    meta: {
      title: 'Отзывы',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/scammers',
    name: 'Scammers',
    component: () => import('./views/scammers.vue'),
    meta: {
      title: 'Мошенники',
      wfPage: '652921c910bae02d8870dddc'
    }
  },
  {
    path: '/write-to-us',
    name: 'WriteToUs',
    component: () => import('./views/writeToUs.vue'),
    meta: {
      title: 'Написать нам',
      wfPage: '652921c910bae02d8870dddc'
    }
  }
];
