<template>
  <div>

    <div data-animation="default"
         class="navbar-component profil w-nav"
         data-easing2="ease"
         data-easing="ease"
         data-collapse="medium"
         data-w-id="16052abf-4955-58fe-7182-10130cae9c64"
         role="banner"
         data-no-scroll="1"
         data-duration="250"
         data-doc-height="1"
         style="z-index: 999; padding-bottom: 10px;"
    >
      <div class="navbar-container profil">
        <a @click="goToHome" class="navbar-logo-link w-nav-brand" style="z-index: 999;">
          <img loading="lazy" src="../../../../../public/images/kolobok.svg" alt="" class="navbar-logo profil">
        </a>
        <nav role="navigation" id="w-node-_16052abf-4955-58fe-7182-10130cae9c68-0cae9c64" class="navbar-menu profil w-nav-menu">
          <a @click="goToPersonalData" :class="['dashboard-link w-inline-block', { 'w--current': currentRoute === '/personal-data' }]">
            <div class="icon-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M7 15H14V17H7V15ZM7 11H17V13H7V11ZM7 7H17V9H7V7ZM19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C4.86 3 4.73 3.01 4.6 3.04C4.21 3.12 3.86 3.32 3.59 3.59C3.41 3.77 3.26 3.99 3.16 4.23C3.06 4.46 3 4.72 3 5V19C3 19.27 3.06 19.54 3.16 19.78C3.26 20.02 3.41 20.23 3.59 20.42C3.86 20.69 4.21 20.89 4.6 20.97C4.73 20.99 4.86 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 2.75C12.41 2.75 12.75 3.09 12.75 3.5C12.75 3.91 12.41 4.25 12 4.25C11.59 4.25 11.25 3.91 11.25 3.5C11.25 3.09 11.59 2.75 12 2.75ZM19 19H5V5H19V19Z" fill="black"></path>
            </svg></div>
            <div>Личные данные</div>
          </a>
          <a @click="goToFAQ" :class="['dashboard-link w-inline-block', { 'w--current': currentRoute === '/faq' }]">
            <div class="icon-small profile w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M19 2H5C3.89 2 3 2.9 3 4V18C3 19.1 3.89 20 5 20H9L12 23L15 20H19C20.1 20 21 19.1 21 18V4C21 2.9 20.1 2 19 2ZM19 18H14.17L13.58 18.59L12 20.17L10.41 18.58L9.83 18H5V4H19V18ZM11 15H13V17H11V15ZM12 7C13.1 7 14 7.9 14 9C14 11 11 10.75 11 14H13C13 11.75 16 11.5 16 9C16 6.79 14.21 5 12 5C9.79 5 8 6.79 8 9H10C10 7.9 10.9 7 12 7Z" fill="#362D4B"></path>
            </svg></div>
            <div>Вопросы и ответы</div>
          </a>
          <a @click="goToActiveLoans" aria-current="page" :class="['dashboard-link w-inline-block', { 'w--current': currentRoute === '/active-loans' }]">
            <div class="icon-small wallet w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.28 15.54L17 14.33L13.5 12.25V8H12Z" fill="#362D4B"></path>
            </svg></div>
            <div>Мои займы</div>
          </a>
          <a @click="goToMap" :class="['dashboard-link w-inline-block', { 'w--current': currentRoute === '/map' }]">
            <div class="icon-small w-embed"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M13.5927 20.2332C14.5374 19.4057 15.9653 18.0179 17.1918 16.257C18.5093 14.3653 19.6 12.0346 19.6 9.5C19.6 5.30917 16.1908 1.9 12 1.9C7.80917 1.9 4.4 5.30917 4.4 9.5C4.4 12.0346 5.49073 14.3653 6.80819 16.257C8.03457 18.0179 9.46239 19.4057 10.4069 20.2332H4.9168H4.8168V20.3332V22V22.1H4.9168H19.0836H19.1836V22V20.3332V20.2332H19.0836H13.5927ZM11.9992 19.1359C11.2394 18.496 9.83368 17.2139 8.60409 15.549C7.33791 13.8345 6.2668 11.7252 6.2668 9.5C6.2668 6.33923 8.83923 3.7668 12 3.7668C15.1608 3.7668 17.7332 6.33923 17.7332 9.5C17.7332 11.72 16.6617 13.8288 15.3951 15.5445C14.1651 17.2106 12.7589 18.4952 11.9992 19.1359Z" fill="#362D4B" stroke="#362D4B" stroke-width="0.2"></path>
              <path d="M11.9998 5.33325C9.70261 5.33325 7.83301 7.20245 7.83301 9.50005C7.83301 11.7977 9.70261 13.6669 11.9998 13.6669C14.297 13.6669 16.1666 11.7977 16.1666 9.50005C16.1666 7.20245 14.297 5.33325 11.9998 5.33325ZM11.9998 12.0001C10.6214 12.0001 9.49981 10.8785 9.49981 9.50005C9.49981 8.12165 10.6214 7.00005 11.9998 7.00005C13.3782 7.00005 14.4998 8.12165 14.4998 9.50005C14.4998 10.8785 13.3782 12.0001 11.9998 12.0001Z" fill="#362D4B"></path>
            </svg></div>
            <div>Карта банкоматов</div>
          </a>

          <a @click="logout" :class="['dashboard-link w-inline-block', { 'w--current': currentRoute === '/map' }]">
            <div class="icon-small w-embed">
              <svg xmlns="http://www.w3.org/2000/svg" height="auto" viewBox="0 0 48 48" width="200px" fill="currentColor">
                <path d="M31.65 32.85q-.7-.7-.7-1.65t.7-1.65l3.2-3.2h-14.4q-1 0-1.675-.675T18.1 24q0-1 .675-1.675t1.675-.675h14.3l-3.3-3.3q-.65-.6-.625-1.55.025-.95.725-1.65t1.7-.675q1 .025 1.7.725l7.2 7.2q.35.35.525.775.175.425.175.875t-.175.875q-.175.425-.525.775l-7.2 7.2q-.65.65-1.6.65-.95 0-1.7-.7Zm-22.7 10.4q-1.95 0-3.35-1.375Q4.2 40.5 4.2 38.55V9.45q0-1.95 1.4-3.35Q7 4.7 8.95 4.7H21.5q1 0 1.675.725T23.85 7.1q0 1-.675 1.675T21.5 9.45H8.95v29.1H21.5q1 0 1.675.675t.675 1.675q0 1-.675 1.675t-1.675.675Z"></path>
              </svg>
            </div>
            <div>Выйти</div>
          </a>
          <div class="w-layout-vflex paliteco profil">
            <a @click="goToDoc('privacy-policy')" class="link-heder profil">Политика конфиденциальности</a>
          </div>
        </nav>
        <div id="w-node-_16052abf-4955-58fe-7182-10130cae9c80-0cae9c64"
             class="navbar-button-wrapper">
          <div class="navbar-menu-button w-nav-button w-nav-button-always">
            <div class="w-layout-hflex mine-menu w-button">
              <div class="button-text-menu">Меню</div>
              <div class="menu-icon2">
                <div class="menu-icon2-line-top"></div>
                <div class="menu-icon2-line-middle">
                  <div class="menu-icon-line-middle-inner"></div>
                </div>
                <div class="menu-icon2-line-bottom"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'HeaderPersonal',
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT").then(() => {
        this.$router.push("/");
      }).catch(() => {
        this.$buefy.toast.open({
          message: 'Ошибка. Попробуйте позже',
          type: 'is-danger'
        });
      });
    },
    goToHome() {
      this.$router.push('/');
    },
    goToMap() {
      this.$router.push('/map');
    },
    goToActiveLoans() {
      this.$router.push('/active-loans');
    },
    goToFAQ() {
      this.$router.push('/faq');
    },
    goToPersonalData() {
      this.$router.push('/personal-data');
    },
    goToDoc(documentType) {
      this.$router.push('/documents/' + documentType);
    },
  }
};
</script>

<style>
</style>
