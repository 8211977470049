<template>
  <div class="gray-card" style="padding: 25px; margin-bottom: 20px">
    <div class="w-layout-vflex title space-between" @click="toggleText">
      <div class="w-layout-hflex space-between w-100 pointer">
        <h1 class="text-mini-3">{{ title }}</h1>
        <img class="img-20 arrow" :src="isOpen ? '/images/up-arrow.svg' : '/images/down-arrow.svg'" alt="arrow">
      </div>
      <div
          :ref="'content' + title"
          :style="{
            maxHeight: isOpen ? (contentHeight + 20) + 'px' : '0px',
            paddingTop: isOpen ? '20px' : '0px'
          }"
          class="accordion-content text-mini gray-2"
      >
        {{ content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      contentHeight: 0, // Динамическая высота для плавного открытия
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.contentHeight = this.$refs['content' + this.title].scrollHeight;
    });
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.$nextTick(() => {
          this.contentHeight = this.$refs['content' + this.title].scrollHeight;
        });
      }
    }
  },
  methods: {
    toggleText() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style scoped>
.accordion-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease, padding-top 0.5s ease;
}
</style>
