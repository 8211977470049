<template>
  <div class="footer">
    <div class="section last">

      <div class="section color">
        <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pb-0" style="border-bottom: none;">
          <div class="w-layout-grid grid-2-colum mt-0 color-footer" style="grid-column-gap: unset;">
            <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100"
                 style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
              <div class="item justify-content-end">
                <a @click="goHome" id="w-node-bd35c41e-61b3-501a-b5b5-76a67d389e27-e94b0a3f" class="navbar-logo-link w-nav-brand"><img src="../../../../../public/images/kolobok.svg" loading="lazy" alt="" class="navbar-logo"></a>
              </div>
            </div>

            <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
                 class="grid-colum right flex between direction-row gap-10"
                 style="padding: 25px 25px 35px; gap: 10px;">
              <div>
                <p class="plane-text big">Поддержка клиентов — бесплатно, круглосуточно</p>
              </div>
<!--              <img width="20px" height="20px" src="../../../../../public/images/telegram.svg" alt="telegram">-->
            </div>
          </div>

        </div>
      </div>

      <div class="section color">
        <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container one w-container pt-0" style="border-bottom: none;">
          <div class="w-layout-grid grid-2-colum mt-0 color-footer" style="grid-column-gap: unset;">
            <div id="w-node-_4f1dd468-2e2a-a103-3ef7-edd160e2b38a-8870dddc" class="grid-colum left w-100 pt-0"
                 style="
               padding: 25px;
               padding-bottom: 35px;
               border-radius: unset;
            ">
              <div class="item">
                <hr class="bold">
                <p class="plane-text">
                  Общество с ограниченной ответственностью микрокредитная компания «КОЛОБОК» (ООО МКК «КОЛОБОК»)
                  зарегистрировано в Государственном реестре микрофинансовых организаций 13 мая 2019 года.
                  <a href="#" @click="goToDocument('certificate-number')">Номер свидетельства 1904060009261</a>

                  <br><br>
                  ООО МКК «КОЛОБОК» использует cookie - файлы с данными о прошлых посещениях сайта
                  для персонализации сервисов и удобства пользователей.<br>
                  Подробнее — Политика конфиденциальности.<br><br>

                  <a href="#" @click="goToDocument('extract-from-registry')">Выписка из государственного реестра микрофинансовых организаций</a>.<br><br>

                  <a href="https://www.cbr.ru/">Официальный сайт Банка России</a>
                </p>
              </div>
            </div>

            <div id="w-node-_8a817dec-33d8-ba64-0999-5e0ddc2f3a91-8870dddc"
                 class="grid-colum right pt-0"
                 style="padding: 25px 25px 35px;">
              <div class="item _2">
                <hr class="bold">
                <p class="plane-text">
                  <a href="https://www.cbr.ru/reception/">Интернет-приемная Банка России</a>
                  <br><br>
                  Вы можете обратиться с жалобой в <a href="https://finombudsman.ru/">Службу финансового уполномоченного</a>.
                  <br><br>
                  Контакты Службы обеспечения деятельности финансового уполномоченного: 119017, г. Москва,
                  Старомонетный переулок, дом 3. Почтовый адрес: 119017, г. Москва, Старомонетный переулок, дом 3,
                  получатель АНО «СОДФУ». Номер телефона:8 (800) 200-00-10 (бесплатный звонок по России).
                  Официальный сайт финансового уполномоченного: <a href="https://finombudsman.ru/">www.finombudsman.ru</a>.
                  <br><br>
                  Оставляя свои контактные данные на сайте, пользователь дает свое согласие на получение информации
                  о компании ООО МКК «КОЛОБОК» и партнерах компании.

                  <br><br>
                  <a href="#" @click="goToWriteToUs">Связаться с нами</a><br>
                  <a href="#" @click="goToContactsAndRequisites">Контакты и реквизиты</a>

                  <br><br>
                  © 2023-2024 ООО МКК «Колобок»
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'Footer',
  methods: {
    goToDocument(name_doc) {
      this.$router.push('/documents/' + name_doc);
    },
    goToContactsAndRequisites() {
      this.$router.push('/contacts');
    },
    goToWriteToUs() {
      this.$router.push('/write-to-us');
    },
    goHome() {
      this.$router.push('/');
    },
    goToPolicyPersonalData() {
      this.$router.push('/documents/privacy-policy');
      window.scrollTo(0, 0);
    },
    goToExtractFromRegistry() {
      this.$router.push('/documents/extract-from-registry');
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style>
.footer a {
  color: black;
}
</style>
