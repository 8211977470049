import store from "@/app/app-state";

const Middlewares = {
  authRequired(to, from, next) {
    let token = store.getters.AUTH_TOKEN;
    let tokenRefresh = store.getters.REFRESH_TOKEN;
    let uuid = store.getters.UUID;

    if (!token || !tokenRefresh || !uuid || token === 'undefined' || tokenRefresh === 'undefined' || uuid === 'undefined') {
      next("/login");
    } else {
      next(undefined);
    }

  },
};

export { Middlewares };
