import { AxiosClient } from '@/app/shared/services';
import { toFormData } from "@/app/shared/services/helpers";

const state = {
};

const getters = {
};

const mutations = {
};

const actions = {
  WRITE_TO_US: (context, formData) => {
    return new Promise((resolve, reject) => {
      // Формируем данные для отправки
      const data_request = {
        subject: formData.items_form_selected,
        message: formData.textOfMessage,
        full_name: formData.full_name,
        phone: formData.phone,
        email: formData.email,
        agreement: formData.agreement,
        files: formData.selectedFiles, // обработка файлов при необходимости
      };

      AxiosClient.post("/feedback/", data_request)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            console.error(error);
            let ex = error.response.data.exception;
            if (ex === 'FileTooLargeException') {
              reject('Размер файла превышает допустимый лимит');
            } else if (ex === 'InvalidEmailException') {
              reject('Неверный формат email');
            } else if (ex === 'PhoneNumberInvalidException') {
              reject('Неверный номер телефона');
            }
            reject('Ошибка отправки данных');
          });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
